<template>
  <span>
    <v-card
      height="600px"
      class="overflow-auto"
      style="min-width:300px"
      outlined
    >
      <v-overlay
        class="primary--text text-center"
        color="grey lighten-4"
        :value="fetchingList"
        :opacity="1"
        absolute
      >
        <v-progress-circular :size="50" color="primary" indeterminate />
        <div class="pa-10">Loading Video List...</div>
      </v-overlay>

      <v-overlay
        class="primary--text text-center"
        color="white"
        :value="(!hasVideos && !fetchingList) || (!fetchingList && !hasVideos)"
        :opacity="1"
        absolute
      >
        <no-list details="No Selected Category" width="200" />
        <!-- <v-btn @click="addNewVideo" class="px-12" color="primary">
        <v-icon class="mr-2"> {{ icons.add }} </v-icon>
        <span> Add New Video </span>
      </v-btn> -->
      </v-overlay>

      <v-card-title v-if="!loading && hasVideos">
        <v-row>
          <v-btn
            v-if="!isSorting"
            color="accent"
            @click="isSorting = true"
            text
          >
            Reorder
          </v-btn>

          <v-btn v-if="isSorting" @click="cancelSorting" color="error" text>
            Cancel
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="isSorting && hasVideoSortChanges"
            v-ripple="{ center: true }"
            color="success"
            @click="saveSortChanges"
            text
          >
            Save Changes
          </v-btn>
        </v-row>
      </v-card-title>

      <v-divider v-if="!loading && hasVideos" />

      <div
        class="video-list--container draggable-list-container"
        style="position: relative"
      >
        <draggable
          v-if="!loading"
          tag="div"
          :list="videos"
          :animation="180"
          :force-fallback="true"
          fallback-class="chosen"
          ghost-class="moving-ghost-list"
          :group="`exercise-videos`"
          handle=".grab-video"
        >
          <template v-for="(video, index) in videos">
            <div :key="index">
              <video-list
                :key="video.id"
                :video="video"
                :fetching="isFetchingList"
                @is-fetching-information="isFetchingInformation"
                @deleted-video="removeVideoFromList"
                @fetch-videos="$emit('fetching-list')"
                :is-sorting="isSorting"
                :draggable="isSorting"
              />
              <v-divider :key="`video-${video.id}-divider`" />
            </div>
          </template>
        </draggable>
      </div>
    </v-card>
  </span>
</template>

<script>
import NoList from '@/components/elements/NoList'
import VideoList from './VideoList'
import Draggable from 'vuedraggable'

import { clone, sortBy, isEqual, mapValues } from 'lodash'
import { mdiClose, mdiPlus } from '@mdi/js'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'VideoCard',

  components: {
    VideoList,
    Draggable,
    NoList,
  },

  props: {
    category: Number,
    items: Array,
    title: String,
    loading: Boolean,
    isSortable: Boolean,
    isFetchingList: Boolean,
  },

  data() {
    return {
      videos: [],
      isSorting: false,
      fetchingList: false,
      videoOriginalCopy: [],
      addNewVideoModalOpen: false,

      icons: {
        add: mdiPlus,
        close: mdiClose,
      },
    }
  },

  created() {
    this.fetchingList = this.loading

    this.videos = this.items
    this.videoOriginalCopy = clone(this.items)
  },

  computed: {
    hasVideos() {
      return !!this.items.length
    },

    hasVideoSortChanges() {
      return !isEqual(this.videos, this.videoOriginalCopy)
    },
  },

  methods: {
    ...mapActions({
      reorderVideos: 'liveWorkoutCategory/reorderVideos',
      removeVideo: 'video/removeVideoCategory',
    }),

    ...mapMutations({
      removeVideoFromList: 'liveWorkoutCategory/removeVideoFromList',
    }),

    isFetchingInformation(isFetching) {
      this.$emit('is-fetching-information', isFetching)
    },

    showVideoInformation(videos) {
      this.$emit('show-video-informartion', videos)
    },

    addNewVideo() {
      this.addNewVideoModalOpen = true
    },

    cancelSorting() {
      this.isSorting = false
      this.videos = sortBy(this.videos, ['order'])
      this.videos = clone(this.videoOriginalCopy)
    },

    async saveSortChanges() {
      let _videos = mapValues(this.videos, 'id')
      await this.reorderVideos({ id: this.category, videos: _videos })
        .then(() => {
          this.videoOriginalCopy = clone(this.videos)
        })
        .finally(() => {
          this.isSorting = false
        })
    },
  },

  watch: {
    items(values) {
      this.videos = values

      if (!this.isSorting) {
        this.videoOriginalCopy = clone(values)
      }
    },

    loading(value) {
      this.fetchingList = value

      if (value) {
        this.cancelSorting()
      }
    },
  },
}
</script>

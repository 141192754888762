<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <thead>
          <tr>
            <th width="5%"></th>
            <th>Name</th>
            <th>Order</th>
            <th>Featured Image</th>
          </tr>
        </thead>

        <tbody>
          <tr :key="key" v-for="(row, key) in list">
            <td>
              <v-btn
                class="bg-primary-gradient primary ml-auto"
                depressed
                small
                @click="edit(row)"
              >
                Edit
              </v-btn>
            </td>
            <td>{{ row.name }}</td>
            <td>{{ row.order }}</td>
            <td>
              <cloudinary-image
                :image="row.cloudinary_image"
                :default_image="require('@/assets/images/no-image.png')"
                :tile="true"
                alt="Feature Category"
                height="70"
                size="70"
                contain
              />
            </td>
          </tr>
        </tbody>

        <table-skeleton-loader v-if="loading" column="4" row />
      </v-simple-table>

      <no-list v-if="!hasData && !loading" details="No Settings Data" />
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline } from '@mdi/js'
import NoList from '@/components/elements/NoList'
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import CloudinaryImage from '@/components/elements/CloudinaryImage'

export default {
  name: 'ListTable',

  components: {
    NoList,
    TableSkeletonLoader,
    CloudinaryImage,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
      icons: { edit: mdiSquareEditOutline },
    },

    list: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data() {
    return {
      spanValues: [
        { name: 'Occupy half of the space', id: 1 },
        { name: 'Occupy all space', id: 2 },
      ],
    }
  },

  computed: {
    hasData() {
      return !!this.list.length
    },
  },

  methods: {
    loadMore() {
      this.$emit('load-more')
    },

    edit(row) {
      this.$emit('edit', row)
    },
  },
}
</script>

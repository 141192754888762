<template>
  <div
    class="video-list--item video-list--active pa-4 align-center d-flex justify-space-between"
    :ripple="true"
    two-line
    @click="fetchInformation"
  >
    <div v-if="draggable" class="mr-2">
      <v-btn @click.stop class="grab-video" icon>
        <v-icon> {{ icons.drag }} </v-icon>
      </v-btn>
    </div>

    <div class="pl-2" :style="{ width: draggable ? '80%' : '90%' }">
      <h1 class="subtitle-1 mb-n1 primary--text">{{ video.title }}</h1>
    </div>
    <div class="ml-auto">
      <v-progress-circular
        v-if="loading"
        class="mr-2"
        color="primary"
        style="margin: 5px;"
        :size="25"
        :width="2"
        indeterminate
      />
      <!-- <v-menu v-if="!loading && !isSorting" left>
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon> {{ icons.actions }} </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-title
              class="red--text px-1"
              @click="deleteSelectedVideo"
            >
              <span class="mr-3"> Delete Video </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </div>
  </div>
</template>

<script>
import { mdiDotsHorizontal, mdiTrashCanOutline, mdiDrag } from '@mdi/js'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'VideoList',

  components: {},

  props: {
    type: String,
    video: Object,
    parentVideo: Object,
    fetching: Boolean,
    disabled: Boolean,
    draggable: Boolean,
    isSorting: Boolean,
    isEditableList: Boolean,
  },

  data() {
    return {
      loading: false,
      deleting: false,
      showEditVideoModal: false,

      icons: {
        drag: mdiDrag,
        actions: mdiDotsHorizontal,
        remove: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    isMain() {
      return this.type === 'main'
    },
  },

  methods: {
    ...mapActions({
      deleteVideo: 'videos/deleteVideo',
    }),

    ...mapMutations({
      clearVideoInformation: 'videos/clearVideoInformation',
    }),

    async fetchInformation(target) {
      // if (this.isSorting) return
      // if (this.fetching) return
      // if (this.type === 'sub') return

      // if (this.type === 'main') {
      //   this.clearVideoInformation('main')
      // }

      // this.loading = true

      // this.$emit('is-fetching-information', this.loading)

      // await this.$sleep(500)

      // this.loading = false

      // this.$emit('is-fetching-information', this.loading)

      window.open(`/videos/${this.video.id}/details`, '_blank').focus()
    },

    editSelectedVideo() {
      this.showEditVideoModal = true
    },

    async deleteSelectedVideo() {
      this.loading = true

      await this.deleteVideo(this.video)

      this.loading = false

      this.$emit('deleted-video', {
        video: this.video,
        type: this.type,
      })
    },
  },
}
</script>

<style>
.long-and-truncated {
  width: 100%;
  line-clamp: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<template>
  <div class="bbr-settings--live-workout-category">
    <main-app-bar>
      <template v-slot:title> On Demand Settings </template>
    </main-app-bar>

    <div class="mt-7 px-10 pb-6">
      <v-row>
        <v-col cols="5">
          <list-table
            :loading="loading"
            @edit="edit"
            :list="liveWorkoutCategories"
          />
        </v-col>
        <v-col cols="3">
          <form autocomplete="off">
            <live-workout-category-form :form-data="form" />
            <v-row>
              <v-col>
                <v-btn
                  class="bg-primary-gradient primary ml-auto mt-4"
                  depressed
                  @click="store"
                >
                  <v-icon class="mr-2">{{ icons.save }}</v-icon>
                  <span v-if="isNew">Save Settings</span>
                  <span v-else>Update Settings</span>
                </v-btn>
              </v-col>
              <v-col>
                <div class="text-right">
                  <v-btn
                    v-if="!isNew"
                    text
                    depressed
                    @click="clearForm"
                    class="mt-3"
                  >
                    Cancel
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </form>
        </v-col>
        <v-col cols="4">
          <v-row>
            <!-- v-if="liveWorkoutVideos.length > 0" -->
            <video-card
              :items="liveWorkoutVideos"
              :category="selectedCategory.id"
              title="Category Videos"
              :isFetchingList="loadingVideos"
              :loading="loadingVideos"
              is-sortable
            />
            <!-- <p v-else>Loading Videos...</p> -->
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiContentSaveOutline, mdiCancel } from '@mdi/js'
import MainAppBar from '@/layouts/shared/MainAppBar'
import ListTable from './components/ListTable'
import VideoCard from '@/components/elements/videos/VideoCard'
import LiveWorkoutCategoryForm from './components/LiveWorkoutCategoryForm'
import Form from '@/utils/form'

const emptyFields = {
  name: null,
  is_booster: false,
  is_active: false,
  order: 0,
  span: 1,
  cloudinary_image: '',
}

export default {
  name: 'LiveWorkoutSettingsPage',

  components: {
    MainAppBar,
    ListTable,
    VideoCard,
    LiveWorkoutCategoryForm,
  },

  data() {
    return {
      icons: { save: mdiContentSaveOutline, cancel: mdiCancel },
      loading: false,
      loadingVideos: false,

      isNew: true,
      form: new Form(emptyFields),
    }
  },

  computed: {
    ...mapState({
      liveWorkoutCategories: (state) => state.liveWorkoutCategory.list,
      selectedCategory: (state) => state.liveWorkoutCategory.selectedData,
      liveWorkoutVideos: (state) => state.liveWorkoutCategory.selectedVideos,
    }),
  },

  created() {
    this.getLiveWorkoutCategory({ search: null })
  },

  destroyed() {
    this.clearList()
    this.clearSelectedData()
    this.clearVideoList()
  },

  methods: {
    ...mapActions({
      getLiveWorkoutCategory: 'liveWorkoutCategory/getDataList',
      getLiveWorkoutVideos: 'liveWorkoutCategory/getVideoData',
      saveData: 'liveWorkoutCategory/saveData',
    }),

    ...mapMutations({
      setSelectedData: 'liveWorkoutCategory/setSelectedData',
      clearSelectedData: 'liveWorkoutCategory/clearSelectedData',
      clearList: 'liveWorkoutCategory/clearList',
      clearVideoList: 'liveWorkoutCategory/clearVideoList',
    }),

    edit(row) {
      this.clearVideoList()
      this.setSelectedData(row)
      let payload = {
        id: row.id,
        name: row.name,
        description: row.description,
        cloudinary_image: row.cloudinary_image
          ? row.cloudinary_image.public_id
          : null,
      }
      this.loadingVideos = true
      this.getLiveWorkoutVideos(row.id).finally(() => {
        this.loadingVideos = false
      })
      this.form = new Form(payload)
      this.isNew = false
    },
    saveVideo() {},
    clearForm() {
      this.clearVideoList()
      this.clearSelectedData()
      this.form = new Form(emptyFields)
      this.isNew = true
    },

    store() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()

      this.saveData(payload)
        .then((data) => {
          this.form.$timeout(() => {
            this.form.$busy = false
            this.clearForm()
            this.getLiveWorkoutCategory({ search: null })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },
  },
}
</script>

<template>
  <v-row class="bbr-program--details-form">
    <v-col cols="12">
      <v-text-field
        hide-details
        v-model="form.name"
        label="Name"
        :error-messages="form.$getError('name')"
        outlined
        flat
      /><v-text-field
        class="pt-5"
        hide-details
        v-model="form.description"
        label="Description"
        :error-messages="form.$getError('description')"
        outlined
        flat
      />
      <v-text-field
        class="pt-5"
        hide-details
        v-model="form.cloudinary_image"
        label="Featured Image"
        :error-messages="form.$getError('icon')"
        outlined
        flat
      />
    </v-col>
  </v-row>
</template>

<script>
import Form from '@/utils/form'

export default {
  name: 'LiveWorkoutCategoryForm',

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  components: {},

  data() {
    return {
      form: this.formData,
    }
  },

  watch: {
    formData() {
      this.form = this.formData
    },
  },

  computed: {},

  methods: {},
}
</script>
